<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}医护人员</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="姓名" prop="name" class="w50 mr16">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="性别" prop="genderDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.genderDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'genderDictionaryItemId')"
				>
					<el-option v-for="(item, index) in sexDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生日" prop="birthDate" class="w50 mr16">
				<el-date-picker value-format="yyyy-MM-dd" v-model.trim="ruleForm.birthDate" :disabled="isShowDetail" type="date" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="电话" prop="phoneNumber" class="w50 mr0">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="身份证">
				<el-input v-model.trim="ruleForm.identificationCode" maxlength="18" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="执业单位" prop="medicalOrganizationId" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.medicalOrganizationId"
					placeholder="请选择"
					@change="changeSelect($event, 'medicalOrganizationId')"
				>
					<el-option
						v-for="(item, index) in organizeDictionaryItemList"
						:key="index"
						:disabled="!item.enabled"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="medicalDepartmentId" label="科室" class="w50 mr0">
				<el-select
					:disabled="isShowDetail || !ruleForm.medicalOrganizationId"
					v-model="ruleForm.medicalDepartmentId"
					placeholder="请选择"
					@change="changeSelect($event, 'medicalDepartmentId')"
				>
					<el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.id" :disabled="!item.enabled"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item label="是否接受转诊信息">
				<el-checkbox v-model="ruleForm.isSection" :disabled="isShowDetail">是否接受转诊信息</el-checkbox>
			</el-form-item> -->
			<el-form-item prop="practiceCategoryDictionaryItemId" label="执业类别" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.practiceCategoryDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'practiceCategoryDictionaryItemId')"
				>
					<el-option v-for="(item, index) in typeDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="positionDictionaryItemId" label="岗位" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.positionDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'positionDictionaryItemId')"
				>
					<el-option v-for="(item, index) in postList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="professionalTitleDictionaryItemId" label="职称" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.professionalTitleDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'professionalTitleDictionaryItemId')"
				>
					<el-option v-for="(item, index) in titleList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="businessTypeDictionaryItemId" label="业务性质" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.businessTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'businessTypeDictionaryItemId')"
				>
					<el-option v-for="(item, index) in buessnissList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="相关介绍" prop="description">
				<el-input type="textarea" autosize :disabled="isShowDetail" v-model.trim="ruleForm.description"></el-input>
			</el-form-item>
			<el-form-item label="上传照片">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="MEDICAL_PRACTICING_CERTIFICATE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
			<el-form-item label="资格证">
				<div class="documentList">
					<span class="add" @click="add" v-if="ruleForm.documentList && ruleForm.documentList.length == 0"
						><i class="el-icon-plus"></i>添加资格证</span
					>
					<div class="documentList-item" v-for="(item, index) in ruleForm.documentList" :key="index">
						<div class="documentList-item-input">
							<el-input placeholder="输入资格证名称" v-model.trim="item.name"> </el-input>
							<span>—</span>
							<el-input v-model.trim="item.number" placeholder="输入资格证件号"></el-input>
							<i class="iconfont icon-tianjia3" @click="add(index)"></i>
							<i class="iconfont icon-a-shanchu2" @click="remove(index)"></i>
						</div>
					</div>
				</div>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifyPhone, verifyIdCard } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		var checkIdCard = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身份证号'));
			} else if (!verifyIdCard(value)) {
				callback(new Error('请输入正确的身份证号'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				medicalDepartmentId: '', //科室
				positionDictionaryItemId: '', //岗位
				professionalTitleDictionaryItemId: '', //职称
				businessTypeDictionaryItemId: '', //业务性质
				description: '',
				isSection: false,
				phoneNumber: '',
				identificationCode: '',
				doctorQualification: '',
				medicalOrganizationId: '',
				practiceCategoryDictionaryItemId: '',
				birthDate: '',
				genderDictionaryItemId: '',
				images: [],
				positionCertification: '',
				documentList: [],
			},
			sexDictionaryItemList: [],
			organizeDictionaryItemList: [],
			typeDictionaryItemList: [],
			departmentList: [],
			postList: [],
			titleList: [],
			buessnissList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				isSection: [{ required: true, message: '请选择', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				identificationCode: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
				doctorQualification: [{ required: true, message: '请输入', trigger: 'blur' }],
				medicalDepartmentId: [{ required: true, message: '请选择', trigger: 'blur' }],
				positionDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				professionalTitleDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				businessTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				medicalOrganizationId: [{ required: true, message: '请选择', trigger: 'blur' }],
				practiceCategoryDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				birthDate: [{ required: true, message: '请选择', trigger: 'blur' }],
				genderDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
				positionCertification: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			attachmentInfo: [],
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachmentGroups();
			this.getSexList();
			this.getOrganizeList();
			this.getType();
			this.getDepartment();
			this.getTitleList();
			this.getBuessnissList();
			this.link = data.link;
			if (data.row) {
				this.setRuleForm(data.row);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			if (!data) return;
			this.ruleForm = JSON.parse(JSON.stringify(data));
			let notation = data.notation ? JSON.parse(data.notation).document : [];
			this.$set(this.ruleForm, 'documentList', JSON.parse(JSON.stringify(notation)));
			this.ruleForm.genderDictionaryItemId = data.genderDictionaryItem?.id || null;
			this.ruleForm.medicalOrganizationId = data.medicalOrganization?.id || null;
			this.ruleForm.practiceCategoryDictionaryItemId = data.practiceCategoryDictionaryItem?.id || null;
			this.ruleForm.medicalDepartmentId = data.medicalDepartment?.id || null;
			this.ruleForm.positionDictionaryItemId = data.positionDictionaryItem?.id || null;
			this.ruleForm.professionalTitleDictionaryItemId = data.professionalTitleDictionaryItem?.id || null;
			this.ruleForm.businessTypeDictionaryItemId = data.businessTypeDictionaryItem?.id || null;
			this.ruleForm.images = data.attachmentDtoMap['COVER'].map((item) => {
				return {
					url: item.link,
					uid: item.id,
					title: item.title,
				};
			});
		},
		// 添加资格证
		add() {
			this.ruleForm.documentList.push({
				name: '',
				number: '',
			});
			this.$forceUpdate();
		},
		// 删除资格证
		remove(index) {
			this.ruleForm.documentList.splice(index, 1);
			this.$forceUpdate();
		},
		// 下拉选择
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			if (type == 'medicalOrganizationId') {
				this.getDepartmentList(val);
				this.ruleForm.medicalDepartmentId = '';
				this.ruleForm[type] = val;
			}
			this.$forceUpdate();
		},
		// 获取性别下拉
		getSexList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'GENDER' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.sexDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取执业单位下拉
		getOrganizeList() {
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: { current: 1, size: 9999 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.organizeDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取执业列表下拉
		getType() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'PRACTICE_CATEGORY', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.typeDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取业务性质列表下拉
		getBuessnissList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'BUSINESS_NATURE_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.buessnissList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取岗位列表下拉
		getDepartment() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'POST', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.postList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取职称列表下拉
		getTitleList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'POSITIONAL_TITLE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.titleList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取科室列表下拉
		getDepartmentList(id) {
			let data = {
				current: 1,
				size: 9999,
				medicalOrganizationId: id,
			};
			this.$http
				.get(this.api['MedicalOrganizationDepartments#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.departmentList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachmentGroups() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'DOCTOR_AND_NURSE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let images = this.ruleForm.images.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'COVER').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'COVER').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let flag = this.ruleForm.documentList.every((item) => {
						if (item.name && item.number) {
							return true;
						}
					});
					if (!flag) return this.$message.error('请填写完整资格证内容');
					let params = {
						...this.ruleForm,
						attachments: [...images],
						notation: JSON.stringify({
							document: this.ruleForm.documentList,
						}),
					};
					console.log(params, 'params');
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi;
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.documentList {
		.add {
			display: inline-block;
			width: 90px;
			color: #1db9b1;
			cursor: pointer;
			.el-icon-plus {
				margin-right: 4px;
			}
		}
		&-item {
			margin-bottom: 12px;
			&-input {
				display: flex;
				gap: 16px;
				.el-input {
					width: 200px;
				}
			}
			i:hover {
				color: #1db9b1;
			}
			.icon-a-shanchu2:hover {
				color: #f56c6c;
			}
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>